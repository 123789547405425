import React, { useState } from "react";
import styled from "styled-components";
import Container from "./Container";
import Cross from "./../../assets/images/iconCross.inline.svg";

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 1rem;
  width: 100%;
  right: 0;
  padding: 0 1rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: 37.5%;
  }
`;

const Inner = styled.div`
  width: 100%;
  color: ${props => props.theme.colours.white};
  background-color: ${(props) => props.theme.colours.navy};
  padding: 1rem;
  border-radius: 0.25rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto;
  position: relative;
  align-items: center;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 0.75rem 1.25rem;
  }

  button {
    margin: 0;
    padding: 0 !important;

    svg {
      height: 2rem !important;
    }
  }

  p {
    margin: 0;
    font-size: ${(props) => props.theme.fluidType(-1.25)};
  }
`;

function CookieConsentPopup() {
  const [show, setShow] = useState(true);
  if (!show) return null;

  return (
    <Holder>
      <Container>
        <Inner>
          <div className="text">
            <p>We use cookies. We will not collect any personally identifiable
              information about site visitors in this process.</p>
          </div>
          <button className="button icon" onClick={() => setShow(false)}><Cross /></button>
        </Inner>
      </Container>
    </Holder>
  );
}

export default CookieConsentPopup;
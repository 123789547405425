import React, { useRef, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

import HeaderScrollTransition from "../atoms/HeaderScrollTransition"
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll"
import Navigation from "../atoms/Navigation"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import Logo from "../../assets/images/logo.inline.svg"

const Holder = styled.header`
  display: none;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.6rem;
  width: 100%;
  padding: 0 4rem;
  z-index: 20;
  background-color: transparent;
  transition: all 0.25s ease;

  svg {
    height: 1.4rem;
    width: auto;
    display: block;
    overflow: visible;
  }
`

const TitleRowLinks = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
`

const NavHolder = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 19;
  background-color: ${(props) => props.theme.colours.navy};
  color: ${(props) => props.theme.colours.white};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transform: ${(props) =>
          props.open ? "translateY(0px)" : "translateY(-100%)"};
  transition: all 0.25s ease;
`


export default function LargeHeader() {
  const tl = useRef(null)
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(null)
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setOpen(false)
  })
  useDisableBodyScroll(open)

  const myClickHandler = (e) => {
    if (active === e.target.ariaLabel && open === true) {
      setOpen(false)
      tl.current.play()
    } else {
      setActive(e.target.ariaLabel)
      setOpen(true)
      tl.current.play()
    }
  }

  const clickHandler = () => {
    setOpen(!open)
  }

  const fetch = useStaticQuery(graphql`
    query {
      allPrismicPost(limit: 2, sort: {order: DESC, fields: last_publication_date}) {
        nodes {
          id
          uid
          data {
            title {
              text
            }
            preview_image {
              gatsbyImageData
              alt
            }
          }
        }
      }
      allPrismicMenu {
        nodes {
          data {
            page_tab {
              text
            }
            tab_type
            page {
              optional_image {
                gatsbyImageData
                alt
              }
              link_display {
                text
              }
              link_type
              scroll
              link {
                uid
                raw
                document {
                  ... on PrismicPage {
                    uid
                    data {
                      body {
                        ... on PrismicPageDataBodyWelcome {
                          id
                          primary {
                            hero_image {
                              alt
                              gatsbyImageData
                            }
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { nodes } = fetch.allPrismicMenu

  return (
    <Holder open={open} ref={ref}>
      <HeaderScrollTransition>
        <TitleRow open={open}>
          <Link to="/">
            <Logo onClick={() => setOpen(false)} className="logo" />
          </Link>
          <TitleRowLinks>
            {nodes.map((node, index) => {
              if (node.data.page_tab.text !== "Portals") {
                return <button key={index} className="button link CircularBold underline" onClick={myClickHandler}
                               aria-label={node.data.page_tab.text}>
                  {node.data.page_tab.text}
                </button>
              } else return null
            })}
            {/*<button className="button icon portal" onClick={myClickHandler} aria-label="Portals"><Portal /></button>*/}
          </TitleRowLinks>
        </TitleRow>
      </HeaderScrollTransition>
      <NavHolder id="top" open={open}>
        <Navigation active={active} clickHandler={clickHandler} tl={tl} fetch={fetch} />
      </NavHolder>
    </Holder>
  )
}

import React, { useState } from "react";
import PropTypes from "prop-types";

function AccordionGroup({ children, clickHandler }) {
  const [active, setActive] = useState(null);

  const handleClick = (clickedItem) => {
    const newActive = clickedItem !== active ? clickedItem : null;
    setActive(newActive);
    if (clickHandler) {
      clickHandler(newActive);
    }
  };

  return React.Children.map(children, (child, i) => (
    React.cloneElement(child, {
      i: i,
      active: active === i,
      clickHandler: handleClick,
    })
  ));
}

AccordionGroup.propTypes = {
  clickHandler: PropTypes.func,
};

export default AccordionGroup;
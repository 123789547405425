import { createGlobalStyle } from "styled-components";

import CircularLight2 from "./../../assets/fonts/Circular/CircularXXWeb-Light.woff";
import CircularLight1 from "./../../assets/fonts/Circular/CircularXXWeb-Light.woff2";

import CircularBold2 from "./../../assets/fonts/Circular/CircularXXWeb-Bold.woff";
import CircularBold1 from "./../../assets/fonts/Circular/CircularXXWeb-Bold.woff2";

import ReplicaLight2 from "./../../assets/fonts/Replica/ReplicaLLWeb-Light.woff";
import ReplicaLight1 from "./../../assets/fonts/Replica/ReplicaLLWeb-Light.woff2";

import ReplicaBold2 from "./../../assets/fonts/Replica/ReplicaLLWeb-Bold.woff";
import ReplicaBold1 from "./../../assets/fonts/Replica/ReplicaLLWeb-Bold.woff2";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Circular Light";
    font-weight: normal;
    src: url('${CircularLight2}') format("woff2"),
    url('${CircularLight1}') format("woff");
  }

  @font-face {
    font-family: "Circular Bold";
    font-weight: normal;
    src: url('${CircularBold2}') format("woff2"),
    url('${CircularBold1}') format("woff");
  }

  @font-face {
    font-family: "Replica Light";
    font-weight: normal;
    src: url('${ReplicaLight2}') format("woff2"),
    url('${ReplicaLight1}') format("woff");
  }

  @font-face {
    font-family: "Replica Bold";
    font-weight: normal;
    src: url('${ReplicaBold2}') format("woff2"),
    url('${ReplicaBold1}') format("woff");
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${(props) => props.theme.fluidType(-0.2)};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    position: relative;
    overflow: auto;
    overflow-x: hidden !important;
    cursor: default;

    * {
      box-sizing: border-box;
    }
  }

  body {
    margin: unset;
    font-family: "Replica Light", Helvetica, Arial, sans-serif;
    color: ${(props) => props.theme.colours.navy};
    background-color: ${(props) => props.theme.colours.white};
  }

  html::-webkit-scrollbar {
    display: none;
  }

  html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .accordion-default {
    visibility: hidden;
    height: 0;
  }

  .accordion-active {
    visibility: visible;
    height: auto;
    display: block;
  }

  .hidden {
    top: -3rem;
  }

  .box_shadow {
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.15);
  }

  h1, h2, .h1, .h2, .CircularBold {
    font-family: "Circular Bold", Helvetica, Arial, sans-serif;
    color: inherit;
    font-weight: normal;
    line-height: 1.2;
    line-spacing: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      position: relative;
    }

    a:hover {
      text-decoration: none;
    }
  }

  h3, .h3, h4, h5, h6, .h4, .h5, .h6, .CircularLight {
    font-family: "Circular Light", Helvetica, Arial, sans-serif;
    color: inherit;
    font-weight: normal;
    line-height: 1.2;
    line-spacing: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: inherit
    }

    a:hover {
      text-decoration: none;
      color: inherit
    }
  }

  .CircularLight {
    font-family: "Circular Light", Helvetica, Arial, sans-serif;
  }

  .CircularBold {
    font-family: "Circular Bold", Helvetica, Arial, sans-serif !important;
  }

  .ReplicaBold {
    font-family: "Replica Bold", Helvetica, Arial, sans-serif;
  }

  .ReplicaLight {
    font-family: "Replica Light", Helvetica, Arial, sans-serif;
  }

  .paddedWidth {
    padding: 2rem;
    @media (${(props) => props.theme.breakpoints.lg}) {
      padding: 0rem;
    }
  }

  .paddedMargin {
    margin: 4rem;
    width: 50%;
    height: auto;
    justify-self: center;
    @media (${(props) => props.theme.breakpoints.lg}) {
      margin: 4rem;
      width: 60%;
    }
  }

  #bars {
    margin-bottom: 0;
  }

  .line::before {
    @media (${(props) => props.theme.breakpoints.lg}) {
      content: "";
      position: absolute;
      right: -2rem;
      top: -2rem;
      bottom: -2rem;
      border-right: 1px solid ${(props) => props.theme.colours.navy};
    }
  }

  .underline {
    display: inline-block;
    position: relative;
  }

  .underline:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.theme.colours.white};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .underline:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    font-family: "Replica Light", Helvetica, Arial, sans-serif;
    line-height: 1.3;
    line-spacing: 0.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    strong {
      //font-family: "Replica Bold", Helvetica, Arial, sans-serif;
      font-weight: bold;
      display: block;
      margin-bottom: 0.75rem;
    }
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a :hover {
    text-decoration: none;
    color: inherit;
  }

  .button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(-0.2)};
    @media (${(props) => props.theme.breakpoints.lg}) {
      ${(props) => props.theme.fluidType(-1)};
    }
    border: 1px solid;
    border-radius: 4px;
    line-height: 1.6rem;
    padding: 0 0.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
    background-color: transparent;
    -webkit-appearance: none;
    text-align: center;
    cursor: pointer;
    transition: 0.25s;

    &.inverse {
      border-color: ${(props) => props.theme.colours.white};
      color: ${(props) => props.theme.colours.white};

      &:hover {
        background-color: ${(props) => props.theme.colours.white};
        color: ${(props) => props.theme.colours.navy};
      }
    }

    &.grey {
      color: ${(props) => props.theme.colours.navy};
      border-color: none;
      background-color: ${(props) => props.theme.colours.grey};

      &:hover {
        background: ${(props) => props.theme.colours.yellow};
        color: ${(props) => props.theme.colours.navy};
      }
    }

    &.inactive {
      pointer-events: none;
      color: ${(props) => props.theme.colours.navy};
      border-color: none;
      background-color: ${(props) => props.theme.colours.grey};
    }

    &.outline {
      color: ${(props) => props.theme.colours.navy};
      border-color: ${(props) => props.theme.colours.navy};
      background-color: none;

      &:hover {
        background: ${(props) => props.theme.colours.navy};
        color: ${(props) => props.theme.colours.white};
      }
    }

    &.large {
      padding: 0.5rem 2rem;
      width: auto;
      @media (${(props) => props.theme.breakpoints.lg}) {
        width: auto;
        padding: 0.5rem 2rem;
        line-height: 2rem;
      }
      @media (${(props) => props.theme.breakpoints.lg}) {
        width: auto;
        padding: 0rem 4rem;
      }
    }

    &.small {
      padding: 0;
      width: auto;
      line-height: inherit;
    }

    &:disabled,
    &[disabled] {
      color: ${(props) => props.theme.colours.navy};
      pointer-events: none;
      background-color: ${(props) => props.theme.colours.darkgrey};
      border: none;
    }

    &.icon {
      height: 100%;
      border: none;
      margin: 0;
      padding: 0.75rem;
      background-color: transparent;

      svg {
        height: 100%;
        width: auto;
        display: block;
      }
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
    }
  }

  h1, .h1 {
    ${(props) => props.theme.fluidType(7)};
    @media ( ${(props) => props.theme.breakpoints.md} ) {
      line-height: 0.9;
      ${(props) => props.theme.fluidType(5)};
    }
  }

  h2, .h2 {
    ${(props) => props.theme.fluidType(4)};
    line-height: 0.9;
  }

  h3, .h3 {
    ${(props) => props.theme.fluidType(3)}
  }

  h4, .h4 {
    ${(props) => props.theme.fluidType(3)} @media (${(props) => props.theme.breakpoints.lg}) {
      ${(props) => props.theme.fluidType(2)}
    }
  }

  h5, .h5 {
    ${(props) => props.theme.fluidType(2)};
  }

  h6, .h6 {
    ${(props) => props.theme.fluidType(1)};
  }

  hr {
    height: 1px;
    background-color: ${(props) => props.theme.colours.navy};
    margin: 0;
  }

  li {
    margin: 0;
    text-decoration: none;
    list-style: none;
  }

  small, p.small {
    ${(props) => props.theme.fluidType(-1)};
  }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  img {
    align-self: center;
    justify-self: center;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  em, i {
    font-style: normal;
  }

  strong {
    //font-family: "Replica Bold", Helvetica, Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.25s;
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub {
    top: 0.4em;
  }

  label {
    ${(props) => props.theme.fluidType(0)};
    line-height: 1.2;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  .rich-text-holder {
    a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
  }

  .fieldset {
    margin: 0.5rem 0;

    &.input,
    &.select {
      label {
        display: none;
      }
    }
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    display: block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(0)};
    width: 100%;
    max-width: 100%;
    padding: 0.2rem 1rem;
    margin: 0.5rem 0;
    border: 1px solid ${(props) => props.theme.colours.black};
    border-radius: 2px;
    line-height: 1.6rem;
    background-color: transparent;
    -webkit-appearance: none;

    &:focus {
      border-color: ${(props) => props.theme.colours.focus};
      outline: none;
    }
  }

  ::placeholder {
    color: ${(props) => props.theme.colours.black};
    opacity: 0.6;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2rem;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: ${(props) => props.theme.colours.black};
  }

  select::-ms-expand {
    display: none;
  }

  .fieldset.select {
    width: 100%;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0;
    margin: 0.5rem 0 !important;
    ${(props) => props.theme.fluidType(0)};
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    grid-template-areas: "select";
    display: grid;
    align-items: center;

    &:after {
      content: "";
      width: 0.8rem;
      height: 0.5rem;
      background-color: ${(props) => props.theme.colours.black};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      justify-self: end;
    }

    select,
    &:after {
      grid-area: select;
    }
  }

  /* Radio */

  .fieldset.radio {
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      padding: 0.2rem 2rem;
      margin-right: 1rem;
      line-height: 1.6rem;

      /* Hide the browser's default radio button */

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom radio button */

      .checkmark {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${(props) => props.theme.colours.black};
        border-radius: 1rem;
      }

      .text {
        position: relative;
        z-index: 2;
      }
    }
  }

  /* Hamburger*/

  .nav-icon3 {
    width: 28px;
    height: 16px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  .nav-icon3 span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    opacity: 1;
    background-color: inherit;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  .nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  .nav-icon3 span:nth-child(4) {
    top: 16px;
  }

  .open span:nth-child(1) {
    top: 16px;
    width: 0%;
    left: 50%;
  }

  .open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 8px;
  }

  .open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 8px;
  }

  .open span:nth-child(4) {
    top: 16px;
    width: 0%;
    left: 50%;
  }
`;

export default GlobalStyle;
export const theme = {
  colours: {
    black: "#000000",
    white: "#FFFFFF",
    grey: "#EDEBEA",
    darkgrey: "#909090",
    navy: "#13284E",
    blue: "#55A8DA",
    purple: "#7D66CC",
    yellow: "#FED11A",
    green: "#24B974",
    red: "#FF5349",
    sheratongrey: "#333536",
    sheratongold: "#AD976E",
    minikinscream: "#FFF7E9",
    minikinsorange: "#EB552E",
    dickiesblue: "#000f9f",
    dickiesgrey: "#F6F4EA",
    dickiescream: "#DDCBA4",
  },
  typography: {
    min: 14,
    max: 24,
    minScreen: 400,
    maxScreen: 1800,
    maxedScreen: 6000,
    scale: {
      min: 1.2,
      max: 1.4,
    },
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (exp) => {
    const type = theme.typography;
    return `

      font-size: ${type.min * Math.pow(type.scale.min, exp)}px;

      @media screen and (min-width: ${type.minScreen}px ) {
        font-size: calc( ${type.min * Math.pow(type.scale.min, exp)}px + 
        (${type.max * Math.pow(type.scale.max, exp)} - 
        ${type.min * Math.pow(type.scale.min, exp)}) *
        (100vw - ${type.minScreen}px)/(${type.maxScreen} - ${type.minScreen}) );
      }

      // @media screen and (min-width: ${type.maxScreen}px ) {
      //   font-size: ${type.max * Math.pow(type.scale.max, exp)}px;
      // }
      `;
  },
  breakpoints: {
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: `min-width: 1200px`,
  },
};

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.section`
  width: 100%;
  max-width: ${(props) =>
    props.fullWidth ? "auto" : `${props.theme.typography.maxedScreen}px`};
  margin: 0 auto;
  padding: 0;
  position: relative;
  hr {
    margin: 0 1rem;
    @media (${(props) => props.theme.breakpoints.lg}) {
      margin: 0 2rem;
    }
  }
`;

export default function Container({ fullWidth, children }) {
  return <Holder fullWidth={fullWidth}>{children}</Holder>;
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
};

Container.defaultProps = {
  fullWidth: false,
};

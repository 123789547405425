import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import PropTypes from "prop-types";

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  > div {
    position: relative;
    min-height: 6rem;
  }
  
  .traditional-custodians {
    ${(props) => props.theme.fluidType(-1.5)};
  }
`;

const Text = styled.h6`
  position: absolute;
  margin: 0;
  opacity: 0;
`;

NavigationText.propTypes = {
  tl: PropTypes.any.isRequired,
};

function NavigationText({ tl }) {
  const t1 = useRef(null);
  const t2 = useRef(null);
  const t3 = useRef(null);
  const t4 = useRef(null);

  useEffect(() => {
    tl.current = gsap
      .timeline({ repeat: -1 })
      .to(t1.current, {
        duration: 8,
        keyframes: { opacity: [0, 1, 1, 0] },
      })
      .to(t2.current, {
        duration: 8,
        keyframes: { opacity: [0, 1, 1, 0] },
      })
      .to(t3.current, {
        duration: 8,
        keyframes: { opacity: [0, 1, 1, 0] },
      })
      .to(t4.current, {
        duration: 8,
        keyframes: { opacity: [0, 1, 1, 0] },
      });
    // Clean up after the component is unmounted
    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <HeaderText>
      <div>
        <Text ref={t1}>
          We ignite greatness in our people, our clients and our
          suppliers.
        </Text>
        <Text ref={t2}>
          We deliver superior quality, certainty and value by disrupting
          the status quo.
        </Text>
        <Text ref={t3}>
          We bring capabilities in design, products, supply, data and
          science to create change.
        </Text>
        <Text ref={t4}>
          We link history, transparency, passion and a focus on the future
          to play our part in building a better tomorrow.
        </Text>
      </div>
      <p className="traditional-custodians">
        Simba acknowledges the Traditional Custodians of all lands on
        which we operate. We recognise their continuing connection to
        land, sea and community; and we pay our respects to their Elders
        past, present and emerging.
      </p>
    </HeaderText>
  );
}

export default NavigationText;
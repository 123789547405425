import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Arrow from "../../../static/up-arrow.inline.svg"
import { animateScroll as scroll } from "react-scroll/modules"

const Holder = styled.div`
  border-bottom: 1px solid ${(props) => props.dark ? "none" : props.theme.colours.navy};
  padding-left: ${(props) => props.dark ? "0.5rem" : "0"};

  &:first-child {
    border-top: none;
  }

  button {
    display: grid;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    grid-template-columns: 1fr 1.5rem;
    grid-gap: 1rem;
    align-items: center;
    width: 100%;
    padding: ${(props) => props.dark ? "1rem 0 0 0rem" : "1.5rem 0rem"};
    text-align: left;
    cursor: pointer;
    white-space: normal;
    transition: all 250ms ease-in-out;
    background-color: ${(props) => props.dark ? props.theme.colours.navy : props.theme.colours.white};
    color: ${(props) => props.dark ? props.theme.colours.white : props.theme.colours.navy};

    > * {
      margin: 0;
    }

    svg {
      height: 1.5rem;
      width: auto;
      transform: ${(props) => props.active ? "rotate(180deg)" : "rotate(0deg)"};
      transition: transform 250ms ease-in-out;

      path {
        fill: ${(props) => props.dark ? props.theme.colours.white : props.theme.colours.navy};
      }
    }
  }
`

const Content = styled.div`
  width: 100%;
  max-height: ${props => props.active ? `${props.height}px` : 0};
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
`

const Inner = styled.div`
  padding: 1rem 0;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

function Accordion({ title, children, clickHandler, active, i, scrollTo, dark }) {
  const [height, setHeight] = useState(null)
  const [pos, setPos] = useState(null)
  const [vw, setVW] = useState(null)
  const container = useRef(null)
  const content = useRef(null)

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth !== vw) {
        if (content.current) {
          setHeight(content.current.offsetHeight)
        }
        if (container.current) {
          setPos(container.current.getBoundingClientRect().top + window.scrollY)
        }
        setVW(window.innerWidth)
      }
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [content, container, vw])

  const handleClick = (i, pos) => {
    clickHandler(i)
    if (pos && scrollTo) {
      scroll.scrollTo(pos - 250, {
        duration: 500,
        smooth: "easeInOutQuint",
      })
    }
  }

  return (
    <Holder active={active} ref={container} dark={dark}>
      <button className="link accordion-title" onClick={() => {
        handleClick(i, pos)
      }}><h4 className={dark ? "ReplicaBold" : ""}>{title}</h4>
        {!dark && <span className="plus-icon"><Arrow /></span>}
      </button>
      <Content className="accordion-content" height={height || 2000} active={active}>
        <Inner ref={content}>
          {children}
        </Inner>
      </Content>
    </Holder>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  active: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  scrollTo: PropTypes.bool,
  category: PropTypes.object,
}

Accordion.defaultProps = {
  clickHandler: null,
  active: false,
  i: 0,
  scrollTo: false,
}

export default Accordion
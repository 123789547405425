import { useEffect } from "react";

export default function useDisableBodyScroll(open) {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
  }, [open]);
}

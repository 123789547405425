import React from "react";
import styled from "styled-components";

import LinkedinIcon from "../../assets/images/simba-icon-linkedin.inline.svg";
import YoutubeIcon from "../../assets/images/simba-icon-youtube.inline.svg";
import Container from "../atoms/Container";
import { Link } from "gatsby";

const Holder = styled.footer`
  color: ${(props) => props.theme.colours.white};
  background-color: ${(props) => props.theme.colours.navy};
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 4rem;
    padding: 4rem 4rem;
    grid-template-areas:
      "first first second second"
      "aus nz sp social"
      "Acknowledges Acknowledges Copyright Copyright";
  }
`;

const First = styled.h4`
  grid-area: first;
  margin: 0;
`;

const Second = styled.h4`
  grid-area: second;
  padding-bottom: 2rem;
  margin: 0;
`;

const Aus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-area: aus;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
`;

const Nz = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-area: nz;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
`;

const Sp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-area: sp;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (${(props) => props.theme.breakpoints.lg}) {
    width: auto;
  }
`;

const Links = styled.a`
  width: fit-content;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    width: fit-content;
  }

  > svg {
    margin: 0;
    margin-right: 0.5rem;
    width: auto;
    height: 1rem;
  }
`;
const Titles = styled.p`
  width: 50%;
  @media (${(props) => props.theme.breakpoints.lg}) {
    width: fit-content;
  }
`;

const Uae = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-area: social;
  gap: 1rem;
  padding-bottom: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    padding: 0;
    justify-content: flex-start;
    gap: 0;
  }
`;

const Acknowledges = styled.div`
  grid-area: Acknowledges;

  p:first-child {
    padding-bottom: 1rem;
  }

  > :last-child {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (${(props) => props.theme.breakpoints.lg}) {
      flex-direction: row;
    }

    p {
      margin: 0;
    }
  }
`;

const Copyright = styled.div`
  grid-area: Copyright;

  p:first-child {
    padding-bottom: 1rem;
  }
`;

export default function Footer() {
  return (
    <Container>
      <Holder>
        <First className="CircularBold">How can we assist you?</First>
        <Second className="CircularLight">
          Get in contact with us, we are here to support you.
        </Second>

        <Aus>
          <Titles className="ReplicaBold">Customer Service<br /> Australia</Titles>
          <Inner>
            <Links className="underline" href="tel:1300 SIMBA1">
              1300 SIMBA1
            </Links>
            <Links className="underline" href="mailto:orders.au@simba.global">
              orders.au@simba.global
            </Links>
          </Inner>
        </Aus>
        <Nz>
          <Titles className="ReplicaBold">Customer Service<br /> New Zealand</Titles>
          <Inner>
            <Links className="underline" href="tel:+64 800 289 839">
              +64 800 289 839
            </Links>
            <Links className="underline" href="mailto:orders.nz@simba.global">
              orders.nz@simba.global
            </Links>
          </Inner>
        </Nz>
        <Sp>
          <Titles className="ReplicaBold">Customer Service<br /> Singapore</Titles>
          <Inner>
            <Links className="underline" href="tel:+65 6745 4455">
              +65 6745 4455
            </Links>

            <Links className="underline" href="mailto:orders.sg@simba.global">
              orders.sg@simba.global
            </Links>
          </Inner>
        </Sp>
        <Uae>
          <Titles className="ReplicaBold">Customer Service<br /> UAE</Titles>
          <Inner>
            <Links className="underline" href="tel:+61 3 9762 2000">
              +61 3 9762 2000
            </Links>
            <Links className="underline" href="mailto:orders.uae@simba.global">
              orders.uae@simba.global
            </Links>
          </Inner>
        </Uae>
        <Acknowledges>
          <p>
            Simba acknowledges the Traditional Custodians of all lands on which we
            operate. We recognise their continuing connection to land, sea and
            community; and we pay our respects to their Elders past, present and
            emerging.
          </p>
          <div>
            <p>View our <Link className="underline" to="/privacy-policy">Privacy Policy</Link></p>
            <span style={{ display: "flex", gap: "1rem" }}>
              <Links style={{ marginRight: "1rem" }}
                     className="underline"
                     href="https://www.linkedin.com/company/simba-global-pty-ltd/mycompany/verification/"
              >
                <LinkedinIcon />
                LinkedIn
              </Links>
              <Links
                className="underline"
                href="https://www.youtube.com/channel/UClGiPm705_SBRz88VI7v8tA/videos"
              >
                <YoutubeIcon />
                Youtube
              </Links>
            </span>
          </div>
        </Acknowledges>
        <Copyright>
          <p>
            Simba Global Operations Centre is located at 289–311 Bayswater Road,
            Bayswater North, Victoria, Australia 3153.
          </p>
          <p>
            {new Date().getFullYear()} Simba Global Pty Ltd. All rights
            reserved. “Simba Global Logo” and “Simba Lion” are trademarks of
            Simba Global.
          </p>
        </Copyright>
      </Holder>
    </Container>
  );
}

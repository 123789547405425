import React, { useEffect, useState } from "react";
import styled from "styled-components";
import throttle from "lodash.throttle";

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.25s ease-in-out;
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.transparent ? "transparent" : props.theme.colours.navy};
  border-bottom: ${props => props.theme.colours.white} 1px solid;

  .text, .line, .portal {
    fill: ${props => props.theme.colours.white};
  }
`;

function HeaderScrollTransition({ children }) {
  const [transparent, setTransparent] = useState(true);
  const [show, setShow] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st < 100) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
      if (st > lastScrollTop) {
        // downscroll
        setShow(false);
      } else {
        // upscroll
        setShow(true);
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };

    const throttledCount = throttle(onScroll, 100);
    window.addEventListener("scroll", throttledCount);
    return () => window.removeEventListener("scroll", throttledCount);

  }, [lastScrollTop]);


  return (
    <Holder className="title-row" transparent={transparent} show={show}>
      {children}
    </Holder>
  );
}

export default HeaderScrollTransition;
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import NavigationInner from "./NavigationInner"
import NavigationText from "./NavigationText"

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6rem;
  padding: 2rem 4rem;
  margin-top: 3rem;
  overflow-y: auto;

  .fade-transition {
    transition: all 150ms ease;
  }

  .fade-out {
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  .fade-in {
    opacity: 1;
    width: 100%;
    height: auto;
    pointer-events: auto;
  }
`

const Inner = styled.div`
  display: ${props => props.tab ? "flex" : "grid"};
  width: 100%;
  grid-template-columns: 2fr 1fr;
`

Navigation.propTypes = {
  active: PropTypes.any,
  clickHandler: PropTypes.any,
  tl: PropTypes.any.isRequired,
}

function Navigation({ fetch, active, clickHandler, tl }) {
  const { nodes } = fetch.allPrismicMenu
  let flaggedNavigation = []

  nodes.forEach((node) => {
    if (node.data.tab_type === "grid") {
      flaggedNavigation.push(node.data.page_tab.text)
    } else return null
  })

  const flag = flaggedNavigation.includes(active)

  return (
    <Holder>
      {flag ? null : <NavigationText tl={tl} />}
      <Inner tab={flag}>
        {nodes.map((node, index) => {
          return <NavigationInner key={index} flag={flag} active={active} node={node.data}
                                  fetch={fetch} clickHandler={clickHandler} tab={flag} />
        })}
      </Inner>
    </Holder>
  )
}

export default Navigation
import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll"
import Lion from "../../assets/images/lion.inline.svg"
import Cross from "../../assets/images/cross.inline.svg"
import Menu from "../../assets/images/menu.inline.svg"
import Portal from "../../assets/images/portal.inline.svg"
import Accordion from "../atoms/Accordion"
import AccordionGroup from "../atoms/AccordionGroup"

const Holder = styled.header`
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  z-index: 20;
  background-color: ${(props) => props.theme.colours.navy};
  color: ${(props) => props.theme.colours.white};
  border-bottom: 1px solid ${(props) => props.theme.colours.white};

  .title-icons {
    height: 2rem;
    width: auto;
    display: block;
  }
`

const TitleRowInner = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  button {
    display: grid;
    background-color: transparent;
    color: ${(props) => props.theme.colours.white};
    border: none;
    padding: 0;
  }
`

const NavHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: ${(props) => (props.open ? "19" : "-10")};
  background-color: ${(props) => props.theme.colours.navy};
  color: ${(props) => props.theme.colours.white};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(-100%)")};
  transition: all 0.2s ease-in-out;
  overflow-y: auto;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1rem;
  margin-top: 3rem;

  .HeaderText {
    margin-top: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  a {
    display: block;
    padding: 0.5rem;
  }
`

export default function SmallHeader() {
  const [open, setOpen] = useState(false)
  useDisableBodyScroll(open)

  return (
    <Holder>
      <TitleRow open={open}>
        <Link to="/">
          <Lion className="title-icons" onClick={() => setOpen(false)} />
        </Link>
        <TitleRowInner>
          <div style={{ width: "30px", display: "flex", justifyContent: "center", cursor: "pointer" }}>
            {open ? <Cross onClick={() => setOpen(!open)} /> :
              <Menu onClick={() => setOpen(!open)} />}
          </div>
          {/*<a href="/"><Portal className="title-icons" /></a>*/}
        </TitleRowInner>
      </TitleRow>
      <NavHolder open={open}>
        <Inner>
          <Grid>
            <AccordionGroup>
              <Accordion title="Divisions" dark={true}>
                <Link to="/commercial" onClick={() => setOpen(false)}>
                  Commercial
                </Link>
                <Link to="/retail" onClick={() => setOpen(false)}>
                  Retail
                </Link>
              </Accordion>
              <Accordion title="Expertise" dark={true}>
                <Link to="/procurement" onClick={() => setOpen(false)}>
                  Procurement
                </Link>
                <Link to="/logistics" onClick={() => setOpen(false)}>
                  Logistics
                </Link>
                <Link to="/quality-assurance" onClick={() => setOpen(false)}>
                  Quality Assurance
                </Link>
                <Link to="/product-design" onClick={() => setOpen(false)}>
                  Product Design
                </Link>
              </Accordion>
              <Accordion title="R+D" dark={true}>
                <Link to="/simbalab" onClick={() => setOpen(false)}>
                  Simba Lab
                </Link>
                <Link to="/smartlinen" onClick={() => setOpen(false)}>
                  Smartlinen™
                </Link>
                <Link to="/edge" onClick={() => setOpen(false)}>
                  Edge™
                </Link>
                <Link to="/polycore" onClick={() => setOpen(false)}>
                  Polycore™
                </Link>
                <Link to="/ecobright" onClick={() => setOpen(false)}>
                  Ecobright™
                </Link>
              </Accordion>
              <Accordion title="Responsibility" dark={true}>
                <Link to="/responsibility" onClick={() => setOpen(false)}>
                  Our Commitment
                </Link>
                <Link to="/ethical-sourcing" onClick={() => setOpen(false)}>
                  Ethical Sourcing
                </Link>
                <Link to="/environment" onClick={() => setOpen(false)}>
                  Environment
                </Link>
                <Link to="/community" onClick={() => setOpen(false)}>
                  Community
                </Link>
              </Accordion>
              <Accordion title="About" dark={true}>
                <Link to="/publications" onClick={() => setOpen(false)}>
                  Publications
                </Link>
                <Link to="/news" onClick={() => setOpen(false)}>
                  News
                </Link>
                <Link to="/values" onClick={() => setOpen(false)}>
                  Values
                </Link>
                <Link to="/team" onClick={() => setOpen(false)}>
                  Team
                </Link>
                <Link to="/story" onClick={() => setOpen(false)}>
                  Story
                </Link>
                <Link to="/careers" onClick={() => setOpen(false)}>
                  Careers
                </Link>
              </Accordion>
              <Accordion title="Shop" dark={true}>
                <a
                  href="https://simbashop.com/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setOpen(false)}
                >
                  Australia
                </a>
                <a
                  href="https://simbashop.com/nz/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setOpen(false)}
                >
                  New Zealand
                </a>
                <a
                  href="https://simbashop.com/sg/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setOpen(false)}
                >
                  Singapore
                </a>
                <a
                  href="https://holsonapparel.com.au/"
                  onClick={() => setOpen(false)}
                >
                  Apparel
                </a>
                <a
                  href="https://retail.simba.global/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setOpen(false)}
                >
                  Retail Catalogue
                </a>
                <a
                  href="https://commercial.simba.global/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setOpen(false)}
                >
                  Commercial Catalogue
                </a>
              </Accordion>
            </AccordionGroup>
            {/*<a href="/" style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}><Portal className="title-icons" /></a>*/}
          </Grid>
        </Inner>
      </NavHolder>
    </Holder>
  )
}
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const ImageGrid = styled.div`
  display: flex;
  column-gap: 1rem;
  flex-direction: ${props => props.tab ? "row" : "column"};
  justify-content: space-evenly;
  width: inherit;
`

const ImageHolder = styled.div`
  aspect-ratio: 16/9;
  max-width: 300px;

  > .gatsby-image-wrapper {
    object-fit: contain;
    height: 100%;
    width: auto;
  }
`

NavigationInner.propTypes = {
  node: PropTypes.any.isRequired,
  active: PropTypes.any,
  flag: PropTypes.any.isRequired,
  fetch: PropTypes.any.isRequired,
  clickHandler: PropTypes.any.isRequired,
  tab: PropTypes.any.isRequired,
}

function NavigationInner({ node, flag, active, fetch, clickHandler, tab }) {
  return <>
    {flag ? null :
      <div className={node.page_tab.text === active ? "fade-in" : "fade-out"}>
        <p className="CircularBold" style={{ marginTop: "0" }}>{node && node.page_tab.text}</p>
        {node.page.map((page, index) =>
          page.link_type === "link" &&
          (
            page.link.raw.url ?
              <a href={page.link.raw.url} onClick={clickHandler} key={index} target={page.link.raw.target}>
                <p style={{ margin: "0.5rem 0" }}>{page.link_display.text}</p>
              </a>
              :
              page.scroll ?
                <Link to={"/" + page.link.uid + "#" + page.link_display.text} onClick={clickHandler} key={index}>
                  <p style={{ margin: "0.5rem 0" }}>{page.link_display.text}</p>
                </Link> :
                <Link to={"/" + page.link.uid} onClick={clickHandler} key={index}>
                  <p style={{ margin: "0.5rem 0" }}>{page.link_display.text}</p>
                </Link>
          ),
        )}
      </div>}

    {active === "About" ?
      <ImageGrid className={node.page_tab.text === active ? "fade-in" : "fade-out"}
                 tab={tab}>
        {fetch.allPrismicPost.nodes.map((post, index) =>
          <Link to={"/" + post.uid} onClick={clickHandler} key={index}>
            <ImageHolder>
              <GatsbyImage alt={post.data.title.text || "Gatsby image"}
                           image={post.data.preview_image.gatsbyImageData} />
            </ImageHolder>
            <p className="CircularBold">{post.data.title.text}</p>
          </Link>,
        )}
      </ImageGrid> :
      // Image grid images & 'fixed' images
      <ImageGrid tab={tab}
                 className={node.page_tab.text === active ? "fade-in" : "fade-out"}>
        {node.page.map((page, index) =>
          page.link_type === "image" &&
          (
            page.link.raw.url ?
              <Link to={page.link.raw.url} onClick={clickHandler} key={index}>
                <ImageHolder>
                  <GatsbyImage
                    alt={page.optional_image.alt ? page.optional_image.alt : page.link.document.data.body[0].primary.hero_image.alt || "Gatsby image"}
                    image={page.optional_image.gatsbyImageData ? page.optional_image.gatsbyImageData : page.link.document.data.body[0].primary.hero_image.gatsbyImageData} />
                </ImageHolder>
                <p className="CircularBold">{page.link_display.text}</p>
              </Link>
              :
              <Link to={"/" + page.link.uid} onClick={clickHandler} key={index}>
                <ImageHolder>
                  <GatsbyImage
                    alt={page.optional_image.alt ? page.optional_image.alt : page.link.document.data.body[0].primary.hero_image.alt || "Gatsby image"}
                    image={page.optional_image.gatsbyImageData ? page.optional_image.gatsbyImageData : page.link.document.data.body[0].primary.hero_image.gatsbyImageData} />
                </ImageHolder>
                <p className="CircularBold">{page.link_display.text}</p>
              </Link>
          ),
        )}
      </ImageGrid>}
  </>
}

export default NavigationInner
import React from "react";
import PropTypes from "prop-types";
import GlobalStyles from "../components/atoms/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/styling";
import Footer from "../components/molecules/Footer";
import LargeHeader from "../components/molecules/LargeHeader";
import SmallHeader from "../components/molecules/SmallHeader";
import CookieConsentPopup from "../components/atoms/CookieConsentPopup";

export default function Index({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <SmallHeader />
        <LargeHeader />
        <main>{children}</main>
        <Footer />
        <CookieConsentPopup />
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};
